import React, { Component } from 'react'
import BagCalculator from "../components/BagCalculator"
import AttributeGroup from "../components/AttributeGroup"
import AttributeButton from "../components/AttributeButton"
import arrowRight from '../images/arrow-right.svg'
import arrowLeft from '../images/arrow-left.svg'
import arrowRightGrey from '../images/arrow-right-grey.svg'

class SealAttributeSelect extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            displayText: 'name',
            attributeGroups: [],
            validAttributes: [],
            attributeNextEnabled: 'disabled',
        }
    }

    componentDidMount() {
        const attributeGroups = []
        this.props.attributes.forEach(attribute => {
            let lookup = attribute.fields['Lookup for Attribute Group']
            if (typeof lookup !== 'undefined') {
                attributeGroups.push(...lookup)
            }
        })

        if (this.props.attributeNextEnabled === '' && this.props.selectedAttributes.length) {
            this.setState({ attributeNextEnabled: '' })
        }

        const uniqueGroups = [...new Set(attributeGroups)];
        this.setState({ attributeGroups: uniqueGroups, validAttributes: this.props.validAttributes })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedAttributes !== this.props.selectedAttributes) {
            if (this.props.attributeNextEnabled === '' && this.props.selectedAttributes.length) {
                this.setState({ attributeNextEnabled: '' })
            }
        }
    }

    handleAttributeClick = attribute => {
        this.props.addAttributes(attribute)
    }
    handleSectorBreadcrumbClick = () => {
        this.props.handleBackClick('sector')
    }

    handleBackClick = () => {
        if (this.props.site === 'masterseal') {
            this.props.handleBackClick('sector');
        } else {
            this.props.handleBackClick('application')
        }
    }

    updateValidAttributes = (validAttributes) => {
        this.setState({ validAttributes })
    }

    render() {
        return (
            <div className={`sector-select ${this.props.site}`}>
                <div className="sector-select__header">
                    <div>
                        <h2>{this.props.attributeSelectTitle}</h2>
                        <div className="breadcrumbs">
                            <a onClick={this.handleSectorBreadcrumbClick}>{this.props.selectedSector}</a>
                            <span><img src={arrowRightGrey} alt="" /></span>
                            {
                                this.props.site === 'masterflow'
                                    ? <a onClick={this.handleApplicationBreadcrumbClick}>{this.props.selectedApplications.join(', ')}</a>
                                    : <a onClick={this.handleApplicationBreadcrumbClick}>{this.props.selectedApplication}</a>
                            }
                        </div>
                    </div>
                    
                    {/* <BagCalculator availableProducts={this.props.availableProducts} products={this.props.products} /> */}
                    <div className="sector-select__nav">
                        
                        <div className="nav-buttons">
                            <span className="nav-button__prev" onClick={() => this.props.handleBackClick(this.props.previous)}>
                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47809 -4.09395e-08L5.41467 0.936585L1.87321 4.44878L5.41467 8.06341L4.52199 9L0.936622 5.38537L3.69988e-05 4.44878L4.47809 -4.09395e-08Z" fill="#7C7C7C"/></svg>
                                Back
                            </span>
                            <span className={`nav-button__next ${this.state.attributeNextEnabled}`} onClick={() => this.state.attributeNextEnabled === '' ? this.props.handleNextClick(this.props.next): null}>
                                Next 
                                <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.936586 9L-4.09394e-08 8.06341L3.54146 4.55122L-3.52463e-07 0.936585L0.892683 -3.90204e-08L4.47805 3.61463L5.41464 4.55122L0.936586 9Z" fill="#000"/></svg>
                            </span>
                        </div>
                    </div>
                </div>

                {this.state.attributeGroups.map(group => {
                    return (
                        <AttributeGroup 
                            group={group} 
                            selectedSector={this.props.selectedSector}
                            allAttributes={this.props.attributes}
                            attributes={this.props.attributes} 
                            validAttributes={this.props.validAttributes} 
                            attributeGroups={this.props.attributeGroups}
                            disabledAttributes={this.props.disabledAttributes}
                            selectedAttributes={this.props.selectedAttributes}
                            updateValidAttributes={this.updateValidAttributes}
                            handleAttributeClick={this.handleAttributeClick}
                            systems={this.props.systems}
                            atLimit={this.props.atLimit}
                        />
                    )
                })}

                <div className="nav-buttons nav-buttons__bottom-set">
                    <span className="nav-button__prev" onClick={() => this.props.handleBackClick(this.props.previous)}>
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.47809 -4.09395e-08L5.41467 0.936585L1.87321 4.44878L5.41467 8.06341L4.52199 9L0.936622 5.38537L3.69988e-05 4.44878L4.47809 -4.09395e-08Z" fill="#7C7C7C"/></svg>
                        Back
                    </span>
                    <span className={`nav-button__next ${this.props.attributeNextEnabled}`} onClick={() => this.props.attributeNextEnabled === '' ? this.props.handleNextClick(this.props.next): null}>
                        Next 
                        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.936586 9L-4.09394e-08 8.06341L3.54146 4.55122L-3.52463e-07 0.936585L0.892683 -3.90204e-08L4.47805 3.61463L5.41464 4.55122L0.936586 9Z" fill="#000"/></svg>
                    </span>
                </div>
            </div>
        )
    }
}

export default SealAttributeSelect
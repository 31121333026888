import React, { Component } from "react"

import SEO from "../components/seo"
import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import Sidebar from "../components/Sidebar"
import SectorSelect from "../components/SectorSelect"
import SealAttributeSelect from "../components/SealAttributeSelect"
import MasterSealBagCalculator from "../components/MasterSealBagCalculator"
import ProductList from "../components/ProductList"
import headerArrows from '../images/header-arrows.png'
import sealIntroImage from '../images/intro_image_masterseal.png'
import lowDustImgSeal from '../images/low_dust_masterseal.jpg'

import Airtable from 'airtable'

const base = new Airtable({ apiKey: 'patNCklhg1H6AionK.9c1220c7fcf2c7b213e5feb66cec8ba4ec36db54743a592d05bc006401577309' }).base('appybfokGG8Y59FxQ')

class WelcomeMasterSeal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeStep: 'sector',
            showSectorSelect: true,
            showAttributeSelect: false,
            showProductPage: false,
            products: [],
            systems: [],
            components: [],
            sectors: [],
            attributes: [],
            attributeGroups: [],
            selectedSector: '',
            selectedApplication: '',
            selectedDuty: '',
            selectedAttributes: [],
            availableProducts: [],
            availableProducts: [],
            disabledAttributes: [],
            sectorNextEnabled: 'disabled',
            applicationNextEnabled: 'disabled',
            attributeNextEnabled: 'disabled',
            atLimit: false,
            site: 'masterseal',
            siteTitle: 'Master Builders Solutions Traffic Coatings',
            introContent: 'Effective waterproofing is a critical component in protecting concrete structures against harsh environmental conditions and traffic wear. The MasterSeal Traffic Coating selector tool will help you quickly match the right traffic coating to the project need and estimate the quantity of material your project will require.',
            stepOneText: 'Step 1: Choose the project',
            sidebarGroupOneTitle: 'Project',
            sidebarGroupTwoTitle: 'Application Requirements',
            lowDustBoxTitle: 'MasterProtect HB 200LR',
            lowDustBoxContent: 'MasterProtect HB 200LR is the perfect solution to help reduce energy consumption in your parking garage while protecting it against the corrosive effects of chloride penetration. It does all of this and improves customer safety. ',
            lowDustBoxLink: 'https://mbcc.sika.com/en-us/products/wall-coatings/high-build/masterprotect-hb-200lr',
            attributeSelectTitle: 'Step 2: Application Requirements',
            validAttributes: [],
            calculatorSystems: [],
        }
    }

    async componentDidMount() {
        // Get all product data from airtable
        try {
            base('Projects').select({ view: 'All Projects' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const sectors = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        this.setState({
                            sectors,
                        });
                        fetchNextPage();
                    }
                );

            base('Attributes').select({ view: 'All Attributes' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const activeAttributes = records.filter(attribute => attribute.fields['Active'])
                        let validAttributes = activeAttributes.map(attribute => attribute.fields.Name)
                        this.setState({
                            attributes: activeAttributes,
                            validAttributes
                        });
                        fetchNextPage();
                    }
                );

            base('Attribute Groups').select({ view: 'All Attribute Groups' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const attributeGroups = records.sort(function (a, b) {
                            if (a.fields.Name > b.fields.Name) return 1;
                            if (a.fields.Name < b.fields.Name) return -1;
                            return 0;
                        });
                        this.setState({
                            attributeGroups
                        });
                        fetchNextPage();
                    }
                );

            base('Systems').select({ view: 'All Systems' })
                .eachPage(
                    (records, fetchNextPage) => {
                        const activeSystems = records.filter(system => system.fields['Active'])
                        this.setState({
                            systems: activeSystems,
                            showSectorSelect: true
                        });
                        fetchNextPage();
                    }
                );

            // There are more than 100 components so we need to deal with the pagination of the results
            let allComponents = []
            base('Components').select({ view: 'All Products' })
                .eachPage(
                    (records, fetchNextPage) => {
                        allComponents = [...allComponents, ...records]
                        this.setState({
                            components: allComponents
                        });
                        fetchNextPage();
                    }
                );
        } catch (err) {
            console.error(err)
        }

        // Get list of sectors from product data

    }

    addSector = sector => {
        this.setState({
            selectedSector: sector,
            sectorNextEnabled: '',
        })
    }

    isTrue = (arr, arr2) => {
        return arr.every(i => arr2.includes(i));
    }

    addAttributes = (attributeObj) => {
        const attribute = {
            name: attributeObj.fields.Name,
            id: attributeObj.id,
            group: attributeObj.fields['Lookup for Attribute Group'][0]
        }
        

        let newAttributes = []
        if (this.state.selectedAttributes.some(item => item.id === attribute.id)) {
            newAttributes = this.state.selectedAttributes.filter(function (el) { return el.id !== attribute.id; });
            this.setState({ disabledAttributes: [], atLimit: false })
        } else {
            if (!this.state.selectedAttributes.some(item => item.id === attribute.id)) {
                newAttributes = [...this.state.selectedAttributes, attribute]
            }
        }

        this.setState({
            selectedAttributes: newAttributes,
            attributeNextEnabled: newAttributes.length ? '' : 'disabled',
        }, () => {
            this.setValidAttributes(this.state.selectedAttributes)
        })
    }

    setValidAttributes = (newAttributes) => {
        const availableProducts = []
        if (this.state.selectedAttributes.length) {
            const validAttributes = []
            this.state.systems.forEach(system => {
                const systemAttrs = system.fields['Lookup for Attributes'] || []
                const systemProjects = system.fields['Lookup for Projects'] || []

                // From https://josephkhan.me/check-if-an-array-is-a-subset-of-another-array-in-javascript/
                const attrsObj = {}
                systemAttrs.forEach((el, index) => {
                    attrsObj[el] = index;
                });

                const systemHasAttributes = this.state.selectedAttributes.every((el) => {
                    return attrsObj[el.name] !== undefined; //because 0 is falsy
                });

                const attributeLookupField = `Lookup for Attributes`
                const systemAttributes = system.fields[attributeLookupField]

                if (systemAttributes) {
                    const appsObj = {}
                    systemAttributes.forEach((el, index) => {
                        appsObj[el] = index;
                    });

                    var systemHasApplications = this.state.selectedAttributes.every((el) => {
                        return appsObj[el.name] !== undefined; //because 0 is falsy
                    });
                }

                const systemHasSector = systemProjects.includes(this.state.selectedSector)

                if (systemHasAttributes && systemHasSector && !availableProducts.includes(system)) {
                    availableProducts.push(system)
                }

                let validAttributes = availableProducts.map(system => {
                    return system.fields['Lookup for Attributes']
                })

                const mergedValidAttributes = [].concat.apply([], validAttributes);
                let uniqueValidAttributes = [...new Set(mergedValidAttributes)];

                console.log(uniqueValidAttributes)

                this.setState({ availableProducts, validAttributes: uniqueValidAttributes })

            })
        } else {
            this.setState({
                availableProducts: [],
                uniqueDisabledAttributes: []
            })
        }
    }

    updateValidAttributes = validAttributes => {
        this.setState({ validAttributes })
    }

    addToCalculator = product => {
        this.setState({ calculatorSystems: [product] })
    }

    handleNextClick = step => {
        switch (step) {
            case 'attribute':
                this.setValidAttributes()
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                })
                break;
            case 'product':
                this.setState({
                    activeStep: 'product',
                    showSectorSelect: false,
                    showAttributeSelect: false,
                    showProductPage: true,
                })
                break;
            default:
                break;
        }
    }

    handleBackClick = step => {
        switch (step) {
            case 'sector':
                this.setState({
                    activeStep: 'sector',
                    showSectorSelect: true,
                    showAttributeSelect: false,
                    showProductPage: false,
                    selectedApplication: '',
                    selectedAttributes: [],
                    availableProducts: [],
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                break;
            case 'attribute':
                this.setValidAttributes()
                this.setState({
                    activeStep: 'attribute',
                    showSectorSelect: false,
                    showAttributeSelect: true,
                    showProductPage: false,
                    // remove these two to preserve selections, but need to fix valid attribute issue first
                    selectedAttributes: [],
                    availableProducts: [],
                    validAttributes: this.state.attributes.map(attribute => attribute.fields.Name)
                })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <div className="site-masterseal">
                        <Layout>
                            <SEO title="MasterSeal Traffic Coating Selector Tool" description="" />
                            <div className="wrapper wrapper--tool">
                                <Sidebar
                                    selectedSector={this.state.selectedSector}
                                    selectedApplication={this.state.selectedApplication}
                                    selectedAttributes={this.state.selectedAttributes}
                                    selectedDuty={this.state.selectedDuty}
                                    availableProducts={this.state.availableProducts}
                                    activeStep={this.state.activeStep}
                                    site={this.state.site}
                                    sidebarGroupOneTitle={this.state.sidebarGroupOneTitle}
                                    sidebarGroupTwoTitle={this.state.sidebarGroupTwoTitle}
                                />
                                <div className="content-wrapper">
                                    <MasterSealBagCalculator products={this.state.products} availableProducts={this.state.availableProducts} systems={this.state.systems} components={this.state.components} activeStep={this.state.activeStep} calculatorSystems={this.state.calculatorSystems}/>
                                    {this.state.showSectorSelect
                                        ? <SectorSelect
                                            sectors={this.state.sectors}
                                            addSector={this.addSector}
                                            selectedSector={this.state.selectedSector}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            sectorNextEnabled={this.state.sectorNextEnabled}
                                            availableProducts={this.state.availableProducts}
                                            products={this.state.products}
                                            site={this.state.site}
                                            siteTitle={this.state.siteTitle}
                                            introContent={this.state.introContent}
                                            introImage={sealIntroImage}
                                            stepOneText={this.state.stepOneText}
                                            lowDustBoxTitle={this.state.lowDustBoxTitle}
                                            lowDustBoxContent={this.state.lowDustBoxContent}
                                            lowDustBoxImage={lowDustImgSeal}
                                            lowDustBoxLink={this.state.lowDustBoxLink}
                                            next="attribute"
                                        />
                                        : null}

                                    {this.state.showAttributeSelect
                                        ? <SealAttributeSelect
                                            attributes={this.state.attributes}
                                            validAttributes={this.state.validAttributes}
                                            attributeGroups={this.state.attributeGroups}
                                            disabledAttributes={this.state.disabledAttributes}
                                            addAttributes={this.addAttributes}
                                            systems={this.state.systems}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplication={this.state.selectedApplication}
                                            selectedAttributes={this.state.selectedAttributes}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            attributeNextEnabled={this.state.attributeNextEnabled}
                                            availableProducts={this.state.availableProducts}
                                            site={this.state.site}
                                            products={this.state.products}
                                            atLimit={this.state.atLimit}
                                            attributeSelectTitle={this.state.attributeSelectTitle}
                                            updateValidAttributes={this.updateValidAttributes}
                                            next="product"
                                            previous="sector"
                                        />
                                        : null}

                                    {this.state.showProductPage
                                        ? <ProductList
                                            availableProducts={this.state.availableProducts}
                                            availableSystems={this.state.availableProducts}
                                            handleNextClick={this.handleNextClick}
                                            handleBackClick={this.handleBackClick}
                                            selectedSector={this.state.selectedSector}
                                            selectedApplication={this.state.selectedApplication}
                                            selectedAttributes={this.state.selectedAttributes}
                                            products={this.state.products}
                                            site={this.state.site}
                                            addToCalculator={this.addToCalculator}
                                            previous="attribute"
                                        />
                                        : null}

                                </div>
                            </div>
                        </Layout>
                    </div>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default WelcomeMasterSeal